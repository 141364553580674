import React from "react";
import { UserInfos } from "types/user.types";
import * as XLSX from "xlsx";

const generateExcelForBulletinAndDownload = (
  data: Partial<UserInfos>[],
  fileName: string
) => {
  const dataArray = data.map((user) => {
    const userCivilAdress =
      user.civilAdress?.numberAdress + " " + user.civilAdress?.adress;
    const userCivilZipCode = user.civilAdress?.zipcode;
    const userCivilCity = user.civilAdress?.city;

    const userChildren = Array.isArray(user.children)
      ? user.children
          .filter((child) => child && (child.name || child.birthdate))
          .map((child) => {
            const childName = child.name || "Nom non spécifié";
            const childBirthdate = child.birthdate
              ? ` le ${child.birthdate}`
              : "";
            return `${childName}${childBirthdate}`;
          })
      : [];

    const userMaritalStatus = user.maritalSituation?.maritalStatus;

    const userWife = user.maritalSituation?.wifeName;

    const userDataArray = [
      "",
      user.id,
      user.grade,
      user.brigade,
      user.grade,
      user.army,
      user.originArmy,
      user.type,
      user.lastName,
      user.firstName,
      userMaritalStatus,
      userWife,
      userCivilAdress,
      userCivilZipCode,
      userCivilCity,
      user.email,
      user.phoneNumber,
      user.landLineNumber,
      user.moreInfos,
      ...userChildren,
    ];

    return userDataArray;
  });

  const workbook = XLSX.utils.book_new();

  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "tri",
      "ID",
      "Grade",
      "Brigade",
      "Grade",
      "Arme",
      "Arme d'origine",
      "Type",
      "Nom",
      "Prénom",
      "Situation Maritale",
      "Epouse",
      "Adresse civile",
      "Code postal",
      "Ville",
      "Email",
      "Portable",
      "Fixe",
      "Infos",
      "Enfants",
    ],
    ...dataArray,
  ]);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName + ".xlsx");

  document.body.appendChild(link);

  link.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default generateExcelForBulletinAndDownload;
